export interface CategoryItem {
  categoryId: number;
  name: string;
}

export const CategoryList = [
  { categoryId: 1, name: '米国CS留学情報' },
  { categoryId: 2, name: 'CS留学のすすめ' },
  { categoryId: 3, name: 'CS海外留学体験記' },
  { categoryId: 4, name: '支援内容紹介' },
  { categoryId: 5, name: 'お問い合わせ' },
];
