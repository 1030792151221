import React from 'react';
import { Link } from 'react-router-dom';
import '../assets/css/ArticleList.css'; // 必要に応じてCSSファイルを作成

interface Article {
    id: number;
    title: string;
    summary: string;
    link: string;
}

interface ArticleListProps {
    selectedCategory: string | null;
    handleCategoryClick: (text: string) => void;
}

const ArticleList: React.FC<ArticleListProps> = ({ selectedCategory, handleCategoryClick }) => {
    // 記事データのサンプル
    const articles: Article[] = [
        {
            id: 1,
            title: 'CS学部のキャンパス紹介',
            summary: 'アメリカ留学で学ぶにあたって重要な拠点となるキャンパスのイメージを持ってもらうために、とある大学のキャンパスを紹介します。',
            link: '/articles/1',
        },
        {
            id: 2,
            title: 'アメリカCS留学の生活体験記（概要編）',
            summary: 'アメリカCS留学で学んだこと、苦労したこと、成功体験をまとめました。',
            link: '/articles/2',
        },
        {
            id: 3,
            title: 'アメリカCS留学の準備体験記（概要編）',
            summary: 'アメリカにコンピュターサイエンス系で留学するにあたっての準備の概要をまとめました。',
            link: '/articles/3',
        },
    ];

    return (
        <div className="article-list-container">
            <div className="article-card-container">
                {articles.map((article) => (
                    <Link to={article.link} key={article.id} className="article-card">
                        <div className="article-content">
                            <h2>{article.title}</h2>
                            <p>{article.summary}</p>
                        </div>
                    </Link>
                ))}
            </div>
        </div>
    );
};

export default ArticleList;