import '../assets/css/CategoryNav.css';
import '../assets/css/global.css';
import { CategoryList } from "../types";
import { Link, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

function CategoryNav(props: { selectedCategory: string | null; handleCategoryClick: (text: string) => void }) {
  const navigate = useNavigate();

  function handleClick(categoryName: string) {
    if (categoryName === 'お問い合わせ') {
      // 外部URLにリダイレクト
      window.location.href = 'https://tech-forming.com/script/mailform/contact/';
    } else {
      // 通常の処理
      props.handleCategoryClick(categoryName);
      navigate(`/categories/${categoryName}`);
    }
  }

  return (
    <nav className="category-nav">
      <ul className="category-buttons">
        {CategoryList.map((category) => (
          <li key={category.categoryId}>
            {/* ボタンのクリックイベントをカスタムハンドラーに渡す */}
            <button
              className={
                props.selectedCategory === category.name
                  ? 'button selected-category-button'
                  : 'button unselected-category-button'
              }
              onClick={() => handleClick(category.name)}
            >
              {category.name}
            </button>
          </li>
        ))}
      </ul>
    </nav>
  );
}

export default CategoryNav;
