import AppHeader from './components/AppHeader';
import AppFooter from './components/AppFooter';
import Home from './components/Home'
import UniversityMap from './components/UniversityMap'
import UniversityRank_USnews from './components/UniversityRank_USnews'
import ContactForm from './components/ContactForm'
import ArticleList from './components/ArticleList'

import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom"
import  React, { useState  } from 'react';//追加

function App() {
    const[selectedCategory, setCategory]= useState<string|null>(null);
    function handleCategoryClick(text: string) {
        setCategory(text);
    }


  return (
      <Router basename={""}>
        <AppHeader selectedCategory={selectedCategory} handleCategoryClick={handleCategoryClick}/>
        <Routes>home
          <Route path="/" element={<Home selectedCategory={selectedCategory} handleCategoryClick={handleCategoryClick}/>} />
          <Route path="/categories/CS海外留学体験記" element={<ArticleList selectedCategory={selectedCategory} handleCategoryClick={handleCategoryClick}/>} />
          <Route path="/map" element={<UniversityMap />} />
          <Route path="/map/:universityName" element={<UniversityMap />} />
            <Route path="/ranking" element={<UniversityRank_USnews />} />
          <Route path="/categories/お問い合わせ" element={<ContactForm />} />
          <Route path="*" element={<div>Page Not Found</div>} />
        </Routes>

        <AppFooter />

      </Router>
  );
}

export default App;
