import '../assets/css/AppFooter.css'
import '../assets/css/global.css'
import {Link} from "react-router-dom";


function AppFooter(){
return(
  <footer className="footer-container">
        <div className="one_stage_footer">
            <div>
                <a className="footer-link" href="index.html">Contact to us</a>
            </div>

            <div></div>
            <div></div>
            <button className="social-media-icons-button">
                <a>
                    <img
                        src={ require("../assets/images/site/facebook.png") }
                        alt="facebook"
                        width="auto"
                        height="20px"
                    />
                </a>
            </button>

            <button className="social-media-icons-button">
                <a>
                    <img
                        src= { require("../assets/images/site/Twitter.png") }
                        alt="X(Twitter)"
                        width="auto"
                        height="15px"
                    />
                </a>
            </button>

            <div></div>
            <div></div>
            <div>
                <a className="footer-link" href="index.html">Access to our stores</a>
            </div>
        </div>

      <div className="one_stage_footer">
          <button className="footer-logo-button">
              <a href="index.html">
                  <img
                      src= { require("../assets/images/site/manga_campus.PNG")}
                      alt="Small_MangaCampus"
                      width="300px"
                      height="auto"
                  />
              </a>
          </button>

          <div className="copy_right">
              ©2023, Manga Campus.com,Inc.
          </div>
      </div>
  </footer>
)
}
export default AppFooter;
