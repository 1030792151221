import '../assets/css/global.css'
import '../assets/css/AppHeader.css';
import '../assets/css/HeaderDropdown.css'; //追加して様子を見る。
import { Link } from 'react-router-dom';
import React, {Dispatch, useContext} from "react";
import CategoryNav from "./CategoryNav";
function AppHeader(props: { selectedCategory: string | null; handleCategoryClick: (text: string) => void }){
    return(
    <header className="header-container">
        <div className="one_stage_header1">
            <h1>
                コンピューターサイエンス留学経験を基にした日本人カウンセラーによる出願対策。志望校情報収集分析、エッセイ添削、インタビュー対策、個別サポート。
            </h1>
        </div>

        <div className="one_stage_header2">
            <CategoryNav  selectedCategory={ props.selectedCategory } handleCategoryClick={props.handleCategoryClick}/>
        </div>
    </header>
)
}
export default AppHeader;

