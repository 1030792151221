import '../assets/css/ContactForm.css'
import '../assets/css/global.css'
import React, { useState } from 'react';

const ContactForm: React.FC = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    body: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const errors: { [key: string]: string } = {};

    if (!formData.name) {
      errors.name = 'お名前を入力してください';
    } else if (formData.name.length > 50) {
      errors.name = 'お名前は50文字以内にしてください';
    }

    if (!formData.email) {
      errors.email = 'メールアドレスを入力してください';
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      errors.email = '有効なメールアドレスを入力してください';
    }

    if (!formData.body) {
      errors.body = '本文を入力してください';
    }

    return errors;
  };

  const handleSubmit = async () => {
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      alert(Object.values(errors).join('\n'));
      return;
    }

    setIsSubmitting(true);
    try {
      const response = await fetch('https://1nm364uife.execute-api.ap-northeast-1.amazonaws.com/v1/send', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error('送信に失敗しました');
      }

      alert('送信に成功しました');
      setFormData({ name: '', email: '', body: '' });
    } catch (error) {
      if (error instanceof Error) {
        alert(error.message);
      } else {
        alert('予期しないエラーが発生しました');
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="container" >
      <h2 className="title">お問い合わせフォーム</h2>
      <form className="field" id="mailForm" onSubmit={(e) => e.preventDefault()}>
        <div className="field">
          <input
            className="input"
            name="name"
            placeholder="お名前"
            value={formData.name}
            onChange={handleChange}
          />
        </div>
        <div className="field">
          <input
            className="input"
            name="email"
            placeholder="返信用メールアドレス"
            value={formData.email}
            onChange={handleChange}
          />
        </div>
        <div className="field">
          <textarea
            className="textarea"
            name="body"
            placeholder="お問い合わせ本文"
            value={formData.body}
            onChange={handleChange}
          ></textarea>
        </div>
        <div>
          <button
            className="button is-medium is-link"
            type="button"
            onClick={handleSubmit}
            disabled={isSubmitting}
          >
            {isSubmitting ? '送信中です...' : '送信する'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default ContactForm;
